import React, {memo} from 'react';

import { Button } from "@mui/material";


const ButtonOutline = (props) => {
    return (
        <Button variant="primaryOutline" type={props.type} className={props.classes} onClick={props.onClick}>
            {props.btnName}
        </Button>
    );
}

export default memo(ButtonOutline);