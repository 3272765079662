import React, {memo} from 'react';

import {Box, Alert, IconButton, Collapse} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';


const SuccessAlert = (props) =>{

  const close = () => {
    props.setOpenSuccess(false);
  }
    

    return (
        <Box className='max-w-[480px] w-full mb-3 ml-auto'>
          <Collapse in={props.openSuccess}>
            <Alert className='bg-success text-white text-base font-normal	px-4 py-[0.13rem] rounded-xl font-sans'
              variant="filled"
              action={
                <IconButton className='text-white pt-1'
                  aria-label="close"
                  onClick={close}
                >
                  <CloseIcon className='w-6 h-6'/>
                </IconButton>
              }
              icon={<CheckCircleOutlinedIcon className='w-6 h-6'/>}
            >
              {props.alretText} 
            </Alert>
          </Collapse>
          
        </Box>
      );
}

export default memo(SuccessAlert);