import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import axios from "axios";
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { useBaseUrlContext } from "./Base_url";
import { useAuth0 } from "@auth0/auth0-react";

import UnAuthModal from "../components/modal/Modal_unAuth";
import ErrorAlert from '../components/alerts/Fail_alert';

import { 
  setTokenReview,
  setTokenRenew,
  setErrAlertOpen,
  setErrAlertText,
  setLogoutHandler
} from '../store/tokenStore'; // The store you use to sync functions

const AuthContext = createContext();

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const { BaseUrl } = useBaseUrlContext();
  const [openUnAuth, setOpenUnAuth] = useState(false);
  const [cookieOptions, setCookieOptions] = useState({});
  const [openFail, setOpenFail] = useState(false);
  const [textFail, setTextFail] = useState('');
  const location = useLocation();
  const { logout } = useAuth0();

  //handle logout

  const handleLogout = useCallback(() => {
    console.log('Token invalid or expired. Logging out...');
    setTimeout(() => {
        logout({ 
          logoutParams: { returnTo: `${window.location.origin}/` } 
        });
    }, 3000);
  }, []);


  useEffect(() => {
    const domain = window.location.hostname;
    const isSecure = domain === 'localhost' ? false : domain.includes('invoiceai.local') ? false : true;
    setCookieOptions({
      path: '/',
      secure: isSecure,
      sameSite: 'lax',
    });
  }, []);

  const tokenRenew = useCallback(async () => {
    const refreshToken = Cookies.get('refreshToken');
    console.log('tokenNewed app');

    if (!refreshToken) {
      console.error('No valid refresh token');
      setOpenUnAuth(true);
      return false;
    }

    try {
      const res = await axios.post(`${BaseUrl}/login`, { refreshToken });
      const data = res.data.result;
      const error = res.data.error;
      console.log('res', data.authorization, error.text);

      if (!error.text && !error.code) {
        Cookies.set('token', data.authorization.token, cookieOptions);
        Cookies.set('expire', data.authorization.expiresAt, cookieOptions);
        Cookies.set('refreshToken', data.authorization.refreshToken, cookieOptions);
        console.log('Token renewed successfully.', location.pathname);
        return true;
      } else {
        console.log('Token renewal error:', error.text);
        return false;
      }

    } catch (err) {
      console.error('Error during token renewal:', err);
      return false;
    }
  }, [BaseUrl, cookieOptions, location.pathname]);

  const tokenReview = useCallback(async () => {
    let expireTime = Math.trunc(JSON.parse(Cookies.get('expire')));
    let currentTime = Math.trunc(Date.now() / 1000);

    if (currentTime < expireTime) {
      return true;
    } else {
      console.log('Token has expired, attempting to renew');
      return await tokenRenew();
    }
  }, [tokenRenew]);

  const popupAction = useCallback((open) => {
    setOpenUnAuth(open);
  }, []);

  const unAuthAccess = useCallback(() => {
    const refreshToken = Cookies.get('refreshToken');
    const token = Cookies.get('token');

    if (!refreshToken || !token) {
      window.location.replace('/');
    }
  }, []);

  const errAlertOpen = useCallback(() => {
    if(!openFail) {
      setOpenFail(true);
    }
  }, [openFail]);

  const errAlertText = useCallback((errText) => {
    setTextFail(errText);
  }, []);

  useEffect(() => {
    if (openFail) {
      const timer = setTimeout(() => {
        setOpenFail(false);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [openFail]);

  // Update the store whenever these functions change
  useEffect(() => {
    setTokenReview(() => tokenReview);
    setTokenRenew(() => tokenRenew);
    setErrAlertOpen(() => errAlertOpen);
    setErrAlertText(() => errAlertText);
    setLogoutHandler(() => handleLogout());
  }, [tokenReview, tokenRenew, errAlertOpen, errAlertText, handleLogout]);


  return (
    <>
      <UnAuthModal 
        openUnAuth={openUnAuth}
        setOpenUnAuth={setOpenUnAuth}
      />
      <div className='absolute top-8 right-8 w-full z-[1999] h-0'>
        <ErrorAlert setOpenFail={setOpenFail} openFail={openFail} alretText={textFail}/>
      </div>
      <AuthContext.Provider 
        value={{
          tokenReview,
          tokenRenew,
          popupAction,
          unAuthAccess,
          errAlertOpen,
          errAlertText
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};
