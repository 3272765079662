import React, {useEffect, useState} from 'react';
import {useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import {Container} from '@mui/material';

import ButtonSecondary from '../components/button/Btn_secondary';

import imageNotConnect from '../assets/unsuccess-login.gif'

const LoginUnsuccessful = (props) => {

    const navigate = useNavigate()
    const [seconds, setSeconds] = useState(3);
    const { logout } = useAuth0();

    useEffect(() => {

        if(seconds < 1){
            console.log('data sign out unsuccessfull',  logout, process.env.AUTH0_DOMAIN, process.env.CLIENT_ID)
            console.log('Auth0 domain:', process.env.REACT_APP_AUTH0_DOMAIN);

            logout({ 
                logoutParams: { returnTo: `${window.location.origin}/` } 
            })
        }

        if (seconds > 0) {
            const timerId = setTimeout(() => setSeconds(seconds - 1), 1000);
            return () => clearTimeout(timerId);
        }
    }, [seconds]);

    return(
            <Container maxWidth="xl" className='flex justify-center'>
                <div className="h-screen flex justify-center items-center flex-col max-w-[80%]">
                    <img src={imageNotConnect} alt='exit icon' className='max-w-[25rem] w-full mb-0'/>
                    <div className="text-6xl font-sans font-semibold mb-5">Login Unsuccessful</div> 
                    <div className="text-xl text-center font-sans font-medium  mb-[3.5rem]">Oops! We couldn't connect you to your tenant. Looks like we hit a bump on the road. No worries, we're sending you back to the login screen in <span className='text-imperialRed'>00:0{seconds}</span> seconds. Hang tight!</div>
                    <ButtonSecondary btnName="Back to Login Screen"  classes={'w-full max-w-[20rem]'} variant={'primaryBtn'} type="button" onClick={() => navigate('/')} disabled={false}/>
                </div>
            </Container>
    )
}

export default LoginUnsuccessful;