import React, {useState} from 'react';
import { useNavigate} from 'react-router-dom';

import { Container, Tabs, Tab, Box, Typography, Accordion, AccordionSummary, AccordionDetails   } from '@mui/material';
import PropTypes from 'prop-types';

import NavBarSimple from "../components/nav/Nav_simple";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box sx={{ p: 3 }}>
                <Typography component={'div'}>{children}</Typography>
            </Box>
            )}
         </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const TermsPolicies = () => {

    const [value, setValue] = useState(0)
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
      };

    return(

        <>
            <NavBarSimple/>
            <div className='mt-[4rem] container-up-margin terms-and-privacy'>
                <Container maxWidth="false">
                    <div className="py-8">
                        <Typography variant="text32" className="font-medium mb-3" component='div'>
                            Terms & policies
                        </Typography>
                        <Typography variant="text20" className="font-medium" component='div'>
                            Effective Date: <span className='text-secondary'>July 05, 2023</span>
                        </Typography>
                    </div>
                    <Tabs value={value}  className='border-b-0' onChange={handleChange} aria-label="tabs">
                        <Tab onClick={()=>{}} label="Terms of use"  {...a11yProps(0)}  className='rounded-t-xl' disableRipple />
                        <Tab onClick={()=>{}} label="Privacy policy"  {...a11yProps(1)} className='rounded-t-xl'  disableRipple/>
                        <Tab onClick={()=>{}} label="Service terms"  {...a11yProps(2)} className='rounded-t-xl'  disableRipple/>
                        <Tab onClick={()=>{}} label="Data processing addendum"  {...a11yProps(3)} className='rounded-t-xl max-w-[24rem]' disableRipple/>
                    </Tabs>
                    <div className='border border-solid border-borderGrayDark mb-8 min-h-[calc(100vh-18.5rem)] rounded-b-xl rounded-tr-xl'>
                        <TabPanel value={value} index={0} className=' p-7 remove-pad'>
                            <Accordion className='shadow-none mb-5'>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="terms-content"
                                    id="terms-header"
                                    className='bg-white rounded-xl border border-solid border-borderGrayDark'
                                >
                                    <Typography component={'div'} variant='text18' className='font-medium leading-6'>Terms & Conditions</Typography>
                                </AccordionSummary>
                                <AccordionDetails className='mt-7'>
                                    <Typography component={'p'} variant='text18' className='font-medium mb-6 leading-6'>
                                        By using our Services, you are agreeing to these terms and conditions. Please read them carefully. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque mattis maximus quam. Nam lobortis hendrerit urna ut pulvinar. Duis consectetur vitae nibh id laoreet. Nam dolor velit, varius et lacus id, blandit auctor nulla. Duis sed felis arcu. Pellentesque eget gravida velit. Quisque erat lacus, consequat quis erat a, semper blandit mauris. Sed fermentum massa nec ante sodales, eu ultrices nunc congue. Curabitur efficitur ac velit nec suscipit.
                                    </Typography>
                                    <Typography component={'p'} variant='text18' className='font-medium mb-6 leading-6'>
                                        By using our Services, you are agreeing to these terms and conditions. Please read them carefully. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque mattis maximus quam. Nam lobortis hendrerit urna ut pulvinar. Duis consectetur vitae nibh id laoreet. Nam dolor velit, varius et lacus id, blandit auctor nulla. Duis sed felis arcu. Pellentesque eget gravida velit. Quisque erat lacus, consequat quis erat a, semper blandit mauris. Sed fermentum massa nec ante sodales, eu ultrices nunc congue. Curabitur efficitur ac velit nec suscipit.
                                    </Typography>
                                    <Typography component={'div'} variant='text18' className='font-semibold mb-6'>
                                        1. Acceptance of Terms
                                    </Typography>
                                    <Typography component={'p'} variant='text18' className='font-medium mb-6 leading-6'>
                                        By accessing or using [Your Company Name]'s services (hereinafter "the Service"), you agree to comply with and be bound by these Terms of Service.
                                    </Typography>
                                    <Typography component={'div'} variant='text18' className='font-semibold mb-6'>
                                        2. Use of the Service
                                    </Typography>
                                    <Typography component={'div'} variant='text18' className='font-medium mb-6 leading-6'>
                                        <Typography component={'p'} variant='text18' className='font-medium mb-2'>
                                            You must be at least 18 years old to use the Service. You agree to use the Service only for 
                                        </Typography>
                                        <Typography component={'p'} variant='text18' className='font-medium mb-2'>
                                            You agree to use the Service only for lawful purposes.
                                        </Typography>
                                        <Typography component={'p'} variant='text18' className='font-medium mb-2'>
                                            You are responsible for maintaining the confidentiality of your account credentials. 
                                        </Typography>
                                        <Typography component={'p'} variant='text18' className='font-medium mb-2'>
                                            You must not interfere with the Service or attempt to gain unauthorized access to it.
                                        </Typography>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='shadow-none'>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="terms-content"
                                    id="terms-header"
                                    className='bg-white rounded-xl border border-solid border-borderGrayDark'
                                >
                                    <Typography component={'div'} variant='text18' className='font-medium leading-6'>Terms & Conditions</Typography>
                                </AccordionSummary>
                                <AccordionDetails className='mt-7'>
                                    <Typography component={'p'} variant='text18' className='font-medium mb-6 leading-6'>
                                        By using our Services, you are agreeing to these terms and conditions. Please read them carefully. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque mattis maximus quam. Nam lobortis hendrerit urna ut pulvinar. Duis consectetur vitae nibh id laoreet. Nam dolor velit, varius et lacus id, blandit auctor nulla. Duis sed felis arcu. Pellentesque eget gravida velit. Quisque erat lacus, consequat quis erat a, semper blandit mauris. Sed fermentum massa nec ante sodales, eu ultrices nunc congue. Curabitur efficitur ac velit nec suscipit.
                                    </Typography>
                                    <Typography component={'p'} variant='text18' className='font-medium mb-6 leading-6'>
                                        By using our Services, you are agreeing to these terms and conditions. Please read them carefully. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque mattis maximus quam. Nam lobortis hendrerit urna ut pulvinar. Duis consectetur vitae nibh id laoreet. Nam dolor velit, varius et lacus id, blandit auctor nulla. Duis sed felis arcu. Pellentesque eget gravida velit. Quisque erat lacus, consequat quis erat a, semper blandit mauris. Sed fermentum massa nec ante sodales, eu ultrices nunc congue. Curabitur efficitur ac velit nec suscipit.
                                    </Typography>
                                    <Typography component={'div'} variant='text18' className='font-semibold mb-6'>
                                        1. Acceptance of Terms
                                    </Typography>
                                    <Typography component={'p'} variant='text18' className='font-medium mb-6 leading-6'>
                                        By accessing or using [Your Company Name]'s services (hereinafter "the Service"), you agree to comply with and be bound by these Terms of Service.
                                    </Typography>
                                    <Typography component={'div'} variant='text18' className='font-semibold mb-6'>
                                        2. Use of the Service
                                    </Typography>
                                    <Typography component={'div'} variant='text18' className='font-medium mb-6 leading-6'>
                                        <Typography component={'p'} variant='text18' className='font-medium mb-2'>
                                            You must be at least 18 years old to use the Service. You agree to use the Service only for 
                                        </Typography>
                                        <Typography component={'p'} variant='text18' className='font-medium mb-2'>
                                            You agree to use the Service only for lawful purposes.
                                        </Typography>
                                        <Typography component={'p'} variant='text18' className='font-medium mb-2'>
                                            You are responsible for maintaining the confidentiality of your account credentials. 
                                        </Typography>
                                        <Typography component={'p'} variant='text18' className='font-medium mb-2'>
                                            You must not interfere with the Service or attempt to gain unauthorized access to it.
                                        </Typography>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </TabPanel>
                        <TabPanel value={value} index={1} className='p-7 remove-pad'>
                            two
                        </TabPanel>
                        <TabPanel value={value} index={2} className='p-7 remove-pad'>
                            Three
                        </TabPanel>
                        <TabPanel value={value} index={3} className='p-7 remove-pad'>
                            Four
                        </TabPanel>
                    </div>
                </Container>
            </div>
        </>    
        
    )
}

export default TermsPolicies;