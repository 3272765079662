import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import useAxiosInstance from '../api/interceptor';

import {Typography, Container, ToggleButton, ToggleButtonGroup } from '@mui/material';
import NavBarSimple from '../components/nav/Nav_simple';

import ButtonSecondary from '../components/button/Btn_secondary';

import CheckIcon from '../assets/tick-square.png'; 


const PickPackageFlow = () => {

    const [packages, setPackages] = useState([])
    const [packageSelect, setPackageSelect] = useState(0);
    const navigate = useNavigate();
    const axiosInstance = useAxiosInstance();

    const handlePackageSelect = (event, sel) => {

        setPackageSelect(sel);
        console.log('sel', sel)

    };

    useEffect(()=>{
        getPackages()
    },[])

    //get packages list

    const getPackages = async() => {

        console.log('getPackages', axiosInstance)

        try{

            //setSklRegion(true)

            const res = await axiosInstance.get(`/packages`)

            let data = res.data.result;
            let error = res.data.error;
            console.log('getPackages data', data)
            setPackages(data.packages)
            setTimeout(()=>{
                //setSklRegion(false)
            },1000)

        } catch (err) {
            console.error('fetchData:', err);
        } 

    }

    //submit selected package

    const submitPackage = async () => {


        console.log('getPackages', axiosInstance)

        try{

            const res = await axiosInstance.post(`/settings`, {
                "packageId": packageSelect
            })

            let data = res.data.result;
            let error = res.data.error;

            console.log('submitPackage data', data)
            navigate('/lets_go')

        } catch (err) {
            console.error('fetchData:', err);
        } 

    }


    return(
       
        <>
            <NavBarSimple isUserLogged={true} hasTenant={true}/>
            <Container maxWidth="false">
                <div className="h-screen flex justify-center items-center flex-col py-14 relative">
                    <Typography variant="text60" className="mb-4 text-center" component='div'>
                        Pick your package
                    </Typography>
                    <Typography variant="text20" className="text-center" component='div'>
                        Start with with zero commitments and pay as you go.
                    </Typography>
                    <div className='my-16 min-h-[20rem] pick-packages-wrapper xl:w-4/5 w-full'>
                        <ToggleButtonGroup
                            value={packageSelect}
                            exclusive
                            onChange={handlePackageSelect}
                            aria-label="packages pick"
                            className='justify-end flex'
                        >
                            {packages && packages.map((item, i) => (
                                <ToggleButton key={i} value={item.value} className='text-left normal-case rounded-[1.75rem] p-8 border-[2px] border-dashed border-borderGrayDark' aria-label="Free" disableRipple>
                                    <div>
                                        <div className='flex justify-between'>
                                            <div className='mb-5'>
                                                <Typography variant="text20" className="font-semibold mb-3" component='div'>
                                                    {item.name}
                                                </Typography>
                                                <Typography variant="textBase" className="leading-5" component='div'>
                                                    {item.intro}
                                                </Typography>
                                            </div>
                                            <div className='ml-4 w-10 h-10'>
                                                {
                                                    packageSelect === item.value ? (
                                                        <img src={CheckIcon} alt='checked' className='w-8'/>
                                                    ):''
                                                }
                                            </div>
                                        </div>
                                        <Typography variant="text20" className="mb-5" component='div'>
                                            <span className='mr-1 font-bold text-3xl'>{item.credit}</span>{item.creditText}
                                        </Typography>
                                        <Typography variant="text24" className="!font-medium" component='div'>
                                            {item.price.amount} {item.price.commit}
                                        </Typography> 
                                    </div>
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </div>
                    <div>
                        <ButtonSecondary variant={'secondaryBtn'} type='button' btnName='Next' classes="w-full sm:w-[30rem] w-[20rem]" onClick={submitPackage} disabled={packageSelect !== 0 ? false : true }/>
                    </div>
                  
                </div>
            </Container>
        </>
    )

}

export default PickPackageFlow;