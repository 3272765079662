import React from 'react';

import {Typography} from '@mui/material';

import loaderIcon from '../assets/Loading1.svg'

const Loading = (props) => {

    return(
        <div className="h-screen flex justify-center items-center flex-col">
            <img className="max-w-[3.75rem] min-h-[3.75rem] mb-8" src={loaderIcon} alt="loading logo"/>
            <Typography variant="text30" className="font-semibold mb-7" component='div'>
                Loading...
            </Typography>
        </div>
    )
}

export default Loading;