// tokenStore.js
let tokenReviewFn = null;
let tokenRenewFn = null;
let errAlertOpenFn = null;
let errAlertTextFn = null;
let logoutHandlerFn = null;

// Setters
export function setTokenReview(fn) {
  console.log('setTokenReview');
  tokenReviewFn = fn;
}
export function setTokenRenew(fn) {
  console.log('setTokenRenew');
  tokenRenewFn = fn;
}
export function setErrAlertOpen(fn) {
  console.log('setErrAlertOpen Token');
  errAlertOpenFn = fn;
}
export function setErrAlertText(fn) {
  console.log('setErrAlertText Token');
  errAlertTextFn = fn;
}
export function setLogoutHandler(fn) {
  console.log('setLogoutHandler');
  logoutHandlerFn = fn;
}

// Getters
export function getTokenReview() {
  console.log('getTokenReview');
  return tokenReviewFn?.();
}

export function getTokenRenew() {
  console.log('getTokenRenew');
  return tokenRenewFn?.();
}

export function getErrAlertOpen() {
  console.log('getErrAlertOpen');
  return errAlertOpenFn?.();
}
export function getErrAlertText() {
  console.log('getErrAlertText');
  return errAlertTextFn?.();
}

export function getLogoutHandler() {
  console.log('getLogoutHandler');
  return logoutHandlerFn?.();
}


