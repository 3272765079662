import React, { createContext, useContext, useEffect, useState} from "react";
import { useLocation } from "react-router";

//create context
const BaseUrlContext = createContext();

//custom hook to use the context 
export const useBaseUrlContext = () => {
    return useContext(BaseUrlContext)
};


//provider component - https://pagerotest.docupathdev.app https://pagerotest.docupathdev.app
export const BaseUrlProvider = ({children}) => {

    const [BaseUrl] = useState(window.location.hostname.includes('localhost') ? 'https://gateway.docupathdev.app/company': window.location.hostname.includes('invoiceai.local')? window.location.origin : `https://gateway.${window.location.hostname.split('.').at(1)}.app/${window.location.hostname.split('.').at(0)}`);
    // const [BaseUrl] = useState('https://t7.docupathdev.app')
    const [env] = useState(window.location.hostname.includes('pagerotest')? true : false)

    // useEffect(()=>{
    //     console.log('***********BaseUrl > ', BaseUrl)
    // },[BaseUrl])

    /*
    useEffect(()=>{

        const hostname = window.location.hostname; // e.g., "sub.example.com"
        const domainParts = hostname.split('.');
        let subDom;

        if (domainParts.length > 2) {
            // Assuming the format is subdomain.domain.tld
            subDom = domainParts.slice(0, domainParts.length - 2).join('.');
        }

        console.log('***********BaseUrl domainParts >', domainParts, subDom)

        if(window.location.hostname.includes('localhost')){

            if(subDom){
                setBaseUrl(`http://${subDom}.docupathdev.app`)
            }
            else{
                setBaseUrl(`http://docupathdev.app`)
            }

        }
        // else if(window.location.hostname.includes('invoiceai.local')){

        //     if(subDom){
        //         setBaseUrl(`http://${window.location.hostname}`)
        //     }
        //     else{
        //         setBaseUrl(`http://docupathdev.app`)
        //     }
            
        // }
        else{
            if(subDom){
                setBaseUrl(`https://${window.location.hostname}`)
            }
            else{
                setBaseUrl(`https://docupath.app`)
            }
        }

    },[location])
    */

    // useEffect(()=>{
    //     console.log('BaseUrl###', BaseUrl)
    // },[BaseUrl])

    return(
        <BaseUrlContext.Provider value={{BaseUrl, env}}>
            {children}
        </BaseUrlContext.Provider>
    )
}