import React from 'react';
import { useOutletContext } from 'react-router-dom';

import {Typography} from '@mui/material';

import 'simplebar-react/dist/simplebar.min.css';
import './settings_styles.scss';
import UnKnowMapTable from './Unknown_map_table';

const UnKnowMap = ({openFail, textFail, openSuccess, textSuccess }) =>{


    const {devMode} = useOutletContext(); 

    
    return(
        <>
            <div className='mb-12'>
                <Typography variant="text32" className="mb-3" component='div'>
                    Unknown mappings
                </Typography>
                <Typography variant="text20" className="mb-3" component='div'>
                    Map fields from your internal format to the destination format
                </Typography>
            </div>
                <div>

                    <UnKnowMapTable devMode={devMode}/>

                </div>
        </> 
    )
}

export default UnKnowMap;