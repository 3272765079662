import React, { createContext, useContext, useState} from "react";
import SuccessAlert from "../components/alerts/Success_alert";
import ErrorAlert from "../components/alerts/Fail_alert";
import WarningAlert from "../components/alerts/Warning_alert";

//create context
const AlertsContext = createContext();

//custom hook to use the context 
export const useAlertsContext = () => {
    const context = useContext(AlertsContext);
    if (!context) {
        throw new Error("useAlertsContext must be used within an AlertsProvider");
    }
    return context;
};

export const AlertsProvider = ({children}) => {

    const [openSuccess, setOpenSuccess] = useState(false);
    const [openFail, setOpenFail] = useState(false);
    const [openWarning, setOpenWarning] = useState(false);
    const [textSuccess, setTextSuccess] = useState("");
    const [textFail, setTextFail] = useState("");
    const [textWarning, setTextWarning] = useState("");

    // Success, Failure, and Warning Alerts with Timeout
    const triggerAlert = (type, text, duration = 5000) => {
        if (type === "success") {
            setTextSuccess(text);
            setOpenSuccess(true);
            setTimeout(() => setOpenSuccess(false), duration);
        } else if (type === "fail") {
            setTextFail(text);
            setOpenFail(true);
            setTimeout(() => setOpenFail(false), duration);
        } else if (type === "warning") {
            setTextWarning(text);
            setOpenWarning(true);
            setTimeout(() => setOpenWarning(false), duration);
        }
    };

    return(
        <>
            <div className="absolute top-8 right-8 w-full z-[1999] h-0">
                <SuccessAlert setOpenSuccess={setOpenSuccess} openSuccess={openSuccess} alretText={textSuccess} />
                <ErrorAlert setOpenFail={setOpenFail} openFail={openFail} alretText={textFail} />
                <WarningAlert setOpenWarning={setOpenWarning} openWarning={openWarning} alretText={textWarning} />
            </div>
            <AlertsContext.Provider value={{triggerAlert}}>
                {children}
            </AlertsContext.Provider>
        </>

    )
}