import React, {memo} from 'react';

import { Button } from "@mui/material";

import './Button.scss';

const ButtonOutlineImg = (props) => {
    return (
        <Button variant={props.variant} type={props.type} className={props.classes} onClick={props.onClick} disabled={props.disabled}>
            <img src={props.img} alt="icon" className="me-3 max-w-[1.5rem]"/>
            {props.btnName}
        </Button>
    );
}

export default memo(ButtonOutlineImg);