import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { useUserContext } from "../context/User_Access";
import { useAuthContext } from "../context/Auth";
import { Container, Typography, Tabs, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import SuccessAlert from "../components/alerts/Success_alert";
import ErrorAlert from "../components/alerts/Fail_alert";
import LogoutModal from "../components/modal/Modal_logout";
import WarningAlert from "../components/alerts/Warning_alert";

import "simplebar-react/dist/simplebar.min.css";
import "../components/settings_comp/settings_styles.scss";

import OrgSettings from "../assets/org_settings.svg";
import OrgSettingsActive from "../assets/org_settings-active.svg";
import KnownMappings from "../assets/Known_mappings.svg";
import KnownMappingsActive from "../assets/Known_mappings-active.svg";
import API from "../assets/APIs.svg";
import APIActive from "../assets/APIs-active.svg";
import UnKnownMappings from "../assets/Unknown-map.svg";
import UnKnownMappingsActive from "../assets/Unknown-map-active.svg";
import Global from "../assets/global.svg";
import GlobalActive from "../assets/global-active.svg";
import Instruct from "../assets/task-square.svg";
import InstructActive from "../assets/task-square-active.svg";
import Report from "../assets/linear-chart.svg";
import ReportActive from "../assets/linear-chart-active.svg";
import Profile from "../assets/profile-circle.svg";
import ProfileActive from "../assets/profile-circle-active.svg";
import ProfileUser from "../assets/profile_user.svg";
import ProfileUserActive from "../assets/profile_user-active.svg";

// Utility for tab accessibility props
function a11yProps(index) {
  return {
    id: `settings-tab-${index}`,
    "aria-controls": `settings-tabpanel-${index}`,
  };
}

// Tab configuration arrays
const TabsArray = [
  {
    img: OrgSettings,
    imgActive: OrgSettingsActive,
    text: "Organization settings",
    accessKey: "/organization",
    path: "./organization_settings",
  },
  {
    img: Report,
    imgActive: ReportActive,
    text: "Usage Insights",
    accessKey: "/reports",
    path: "./usage_insights",
  },
  {
    img: ProfileUser,
    imgActive: ProfileUserActive,
    text: "User Management",
    accessKey: "/users",
    path: "./user_management",
  },
  {
    img: API,
    imgActive: APIActive,
    text: "Destination Format & APIs",
    accessKey: "/mappings",
    path: "./destination_format_and_apis",
  },
  {
    img: KnownMappings,
    imgActive: KnownMappingsActive,
    text: "Known mappings",
    accessKey: "/known_mappings",
    path: "./known_mappings",
  },
  {
    img: UnKnownMappings,
    imgActive: UnKnownMappingsActive,
    text: "Unknown mappings",
    accessKey: "unknown_map",
    path: "./unknown_mappings",
  },
  {
    img: Global,
    imgActive: GlobalActive,
    text: "Regions & countries",
    accessKey: "/region",
    path: "./regions_and_countries",
  },
  {
    img: Instruct,
    imgActive: InstructActive,
    text: "Instruction builds",
    accessKey: "/instructions",
    path: "./instruction_builds",
  },
  {
    img: Profile,
    imgActive: ProfileActive,
    text: "User settings",
    accessKey: true,
    path: "./user_settings",
  },
];

// Main Settings Component
const Settings = (props) => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFail, setOpenFail] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [textSuccess, setTextSuccess] = useState("");
  const [textFail, setTextFail] = useState("");
  const [textWarning, setTextWarning] = useState("");
  const [openLogout, setOpenLogout] = useState(false);
  const [tabsArray, setTabsArray] = useState([]);
  const navigate = useNavigate();
  const { devMode, accessControl, orgName } = useUserContext();
  const { unAuthAccess } = useAuthContext();

  // Memoized filtered tabs
  const filteredTabs = useMemo(() => {
    const tabsToFilter = devMode ? TabsArray.map((tab) => ({ ...tab, path: `${tab.path}?devMode=true` })) : TabsArray;
    return accessControl
      ? tabsToFilter.filter(
          (tab) => (Object.keys(accessControl).includes(tab.accessKey) && accessControl[tab.accessKey]?.write) || tab.accessKey === true
        )
      : [];
  }, [devMode, accessControl]);

  // Sync tabs array with filteredTabs
  useEffect(() => {
    setTabsArray(filteredTabs);
  }, [filteredTabs]);

  // Redirect to unAuthAccess on load
  useEffect(() => {
    unAuthAccess();
  }, [navigate]);

  // Sync tab value with current route
  useEffect(() => {
    if (!tabsArray.length) return;
    const currentPath = props.pathName.replace("/settings", "");
    const matchingTabIndex = tabsArray.findIndex((tab) => tab.path === `.${currentPath}`);
    if (matchingTabIndex !== -1) {
      props.setTabValue(matchingTabIndex);
    }
  }, [tabsArray, props.pathName]);

  // Success, Failure, and Warning Alerts with Timeout
  const triggerAlert = (type, text, duration = 5000) => {
    if (type === "success") {
      setTextSuccess(text);
      setOpenSuccess(true);
      setTimeout(() => setOpenSuccess(false), duration);
    } else if (type === "fail") {
      setTextFail(text);
      setOpenFail(true);
      setTimeout(() => setOpenFail(false), duration);
    } else if (type === "warning") {
      setTextWarning(text);
      setOpenWarning(true);
      setTimeout(() => setOpenWarning(false), duration);
    }
  };

  // Handle Tab Change
  const settingsHandle = (event, newValue) => {
    const selectedTab = tabsArray.at(newValue);
    if (!selectedTab) return;
    props.setTabValue(newValue);
    navigate(selectedTab.path);
  };

  return (
    <>
      <LogoutModal setOpenLogout={setOpenLogout} openLogout={openLogout} />
      <div className="absolute top-8 right-8 w-full z-[1999] h-0">
        <SuccessAlert setOpenSuccess={setOpenSuccess} openSuccess={openSuccess} alretText={textSuccess} />
        <ErrorAlert setOpenFail={setOpenFail} openFail={openFail} alretText={textFail} />
        <WarningAlert setOpenWarning={setOpenWarning} openWarning={openWarning} alretText={textWarning} />
      </div>
      <div className="mt-[4.5rem] container-up-margin">
        <Container maxWidth="false">
          <Grid container spacing={2} className="min-height-grid-settings">
            <Grid xs={12} md={2} lg={3} className="md:border-r hidden md:block border-0 md:border-solid md:border-r-borderGrayDark px-5 pt-10 pb-5">
              <div className="mb-10">
                <Typography component="div" variant="text20" className="mb-2 font-semibold hidden lg:block">
                  {orgName}
                </Typography>
              </div>
              <SimpleBar>
                <Tabs
                  orientation="vertical"
                  value={props.tabValue}
                  onChange={settingsHandle}
                  aria-label="settings"
                  className="settings-tab"
                >
                  {tabsArray.map((item, index) => (
                    <Tab
                      key={index}
                      icon={<img src={props.tabValue === index ? item.imgActive : item.img} alt={item.text} className="mr-5 w-6 h-6" />}
                      label={<span className="md:hidden lg:block capitalize">{item.text}</span>}
                      className="border-0 mb-2 rounded-xl text-left justify-start p-5"
                      iconPosition="start"
                      disableTouchRipple
                      {...a11yProps(index)}
                    />
                  ))}
                </Tabs>
              </SimpleBar>
            </Grid>
            <Grid xs={12} md={10} lg={9} className="pl-5 pt-10 pb-5 pr-5">
              <Outlet context={{ triggerAlert, devMode }} />
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Settings;
