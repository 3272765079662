import React, {memo} from 'react';

import { Button } from "@mui/material";


const ButtonSecondary = (props) => {
    return (
        <Button variant={props.variant} type={props.type} className={`${props.classes} btn-two-state`} onClick={props.onClick} disabled={props.disabled}>
            {props.btnName}
        </Button>
    );
}

export default memo(ButtonSecondary);