import React, {useState, memo, useCallback, useMemo} from 'react';
import {Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import { useUserContext } from '../../context/User_Access';
import { useBaseUrlContext } from '../../context/Base_url'

import {AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Avatar, Tooltip, MenuItem} from '@mui/material';

import {ThemeProvider } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import LogoutModal from '../modal/Modal_logout';

import { ThemeCustom } from "../../styles/Theme";
import NavStyle from './Nav_style.module.scss';

import logo from '../../assets/Docupath-Logo.svg';
import logoTestEnv from '../../assets/logo-test-env.svg'

const NavArray = [
  {text:'Home', navigate:'/file_upload', fun:false, settingsItem:0, accessKey:true },
  {text:'Organization settings',  navigate:'/settings/organization_settings', fun:false, accessKey:'/organization'},
  {text:'Usage Insights',  navigate:'/settings/usage_insights', fun:false, accessKey:'/reports'},
  {text:'User Management',  navigate:'/settings/user_management', fun:false, accessKey:'/users'},
  {text:'Destination Format & APIs', navigate:'/settings/destination_format_and_apis', fun:false, accessKey:'/mappings' },
  {text:'Known mappings',  navigate:'/settings/known_mappings', fun:false, accessKey:'/known_mappings'},
  {text:'Unknown mappings',  navigate:'/settings/unknown_mappings', fun:false, accessKey:'unknown_map'},
  {text:'Regions & countries',  navigate:'/settings/regions_and_countries', fun:false, accessKey:'/region'},
  {text:'Instruction builds',  navigate:'/settings/instruction_builds', fun:false, accessKey:'/instructions'},
  {text:'User settings',  navigate:'/settings/user_settings', fun:false, accessKey:true},
  {text:'Sign out', fun:true, accessKey:true }
]

const NavArrayDevmode = [
  {text:'Home', navigate:'/file_upload', fun:false, settingsItem:0, accessKey:true },
  {text:'Organization Settings',  navigate:'/settings/organization_settings?devMode=true', fun:false, accessKey:'/organization'},
  {text:'Usage Insights',  navigate:'/settings/usage_insights?devMode=true', fun:false, accessKey:'/reports'},
  {text:'User Management',  navigate:'/settings/user_management?devMode=true', fun:false, accessKey:'/users'},
  {text:'Destination Format & APIs', navigate:'/settings/destination_format_and_apis?devMode=true', fun:false, accessKey:'/mappings' },
  {text:'Known Mappings',  navigate:'/settings/known_mappings?devMode=true', fun:false, accessKey:'/known_mappings'},
  {text:'Unknown Mappings',  navigate:'/settings/unknown_mappings?devMode=true', fun:false, accessKey:'unknown_map'},
  {text:'Regions & countries',  navigate:'/settings/regions_and_countries?devMode=true', fun:false, accessKey:'/region'},
  {text:'Instruction builds',  navigate:'/settings/instruction_builds?devMode=true', fun:false, accessKey:'/instructions'},
  {text:'User settings',  navigate:'/settings/user_settings?devMode=true', fun:false, accessKey:true},
  {text:'Sign out', fun:true, accessKey:true }
]

const NavBar = (props) => {

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [openLogout, setOpenLogout] = useState(false);
  const navigate = useNavigate();
  const {devMode, userName, userEmail, orgName, accessControl, orgLogoCustom} = useUserContext()
  const {env} = useBaseUrlContext()

  const navItem = useMemo(() => {
    if (devMode) {
      return accessControl
        ? NavArrayDevmode.filter(tab =>
            tab.accessKey === true || 
            (Object.keys(accessControl).includes(tab.accessKey) && accessControl[tab.accessKey].write)
          )
        : [];
    } else {
      return accessControl
        ? NavArray.filter(tab =>
            tab.accessKey === true || 
            (Object.keys(accessControl).includes(tab.accessKey) && accessControl[tab.accessKey].write)
          )
        : [];
    }
  }, [devMode, accessControl]);


  const isMobile = useMediaQuery({
    query: '(max-width: 600px)'
  })

  const handleOpenUserMenu = useCallback((event) => {
    setAnchorElUser(event.currentTarget);
  }, []);

  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null);
  }, []);

  const logout = useCallback(() =>{

    handleCloseUserMenu();
    setOpenLogout(true);

  }, [handleCloseUserMenu])

  const settings = useCallback((route, index) => {

    handleCloseUserMenu();   
    navigate(route); 
    props.setTabValue(index-1);
    
  }, [navigate, props.setTabValue, handleCloseUserMenu])

  return (
    <ThemeProvider theme={ThemeCustom}>
      <LogoutModal setOpenLogout={setOpenLogout} openLogout={openLogout} />
        <AppBar position="fixed" className={`bg-white shadow-none ${props.navBarBoader}`}>
        <Container maxWidth="false">
            <Toolbar disableGutters className='flex justify-between'>
                <div className='flex items-center'>
                  <Link to={devMode ? '/document_review?devMode=true':'/document_review'} className='flex items-center'>
                    <img src={env ? logoTestEnv:(orgLogoCustom ? orgLogoCustom : logo)} alt='Logo' className={`${NavStyle['nav-img-width']} object-contain w-[9rem] h-[1.875rem]`}/>
                  </Link>  
                </div>
                <Box sx={{ flexGrow: 0 }} className="flex items-center">
                    <Box component={'div'} onClick={handleOpenUserMenu} className='flex items-center cursor-pointer' >
                        <Typography className='mr-6 hidden sm:block' variant="text20" component={'div'}>{userName}</Typography>
                        <Tooltip title="Open settings">
                        <IconButton sx={{ p: 0, '&:hover':{backgroundColor:'transparent'} }} disableTouchRipple>
                            <Avatar alt={userName} src="/static/images/avatar/1.jpg" className='bg-primary' />
                            <ArrowDropDownIcon />
                        </IconButton>
                        </Tooltip>
                    </Box>
                    <Menu
                      sx={{ mt: '45px', borderRadius:'1.25rem' }}
                      id="menu-navBar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                      className='main-nav'
                    >   
                        <Typography component={'div'} variant="userDropdown" className={`${NavStyle['nav-item-wrapper']} ${NavStyle['nav-item-text-18']}`}>
                          {userName}
                        </Typography>
                        <Typography component={'div'} variant="userDropdown" className={`${NavStyle['nav-item-wrapper']} ${NavStyle['nav-text-sonicSilver']} ${NavStyle['nav-item-text']}`}>
                          {userEmail}
                        </Typography>
                        <Typography component={'div'} variant="userDropdown" className={`${NavStyle['nav-item-wrapper']} ${NavStyle['nav-item-text-gray']}`}>
                          {orgName}
                        </Typography>
                        {navItem.map((item, index) => (
                          <MenuItem  key={index} onClick={item.fun ? logout : ()=>{ settings(item.navigate, index)}} className={`${NavStyle['nav-item-wrapper']} main-nav-item`} >
                              <Typography component={'div'} variant="userDropdown" className={`${NavStyle['nav-item']} ${NavStyle['nav-item-capitalize']}`}>
                                {item.text}
                              </Typography>
                          </MenuItem>
                        ))}
                    </Menu> 
                </Box>
            </Toolbar>
        </Container>
        </AppBar>
    </ThemeProvider>    
  );
}

export default memo(NavBar);