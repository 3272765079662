import React, {memo} from 'react';

import {Box, Alert, IconButton, Collapse} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';


const ErrorAlert = (props) =>{

  const close = () => {
    props.setOpenFail(false);
  }

    return (
        <Box className='max-w-[480px] w-full mb-3 ml-auto'>
          <Collapse in={props.openFail}>
            <Alert className='bg-error text-white text-base font-normal	px-4 py-[0.13rem] rounded-xl font-sans'
              variant="filled"
              action={
                <IconButton className='text-white pt-1'
                  aria-label="close"
                  onClick={close}
                >
                  <CloseIcon className='w-6 h-6'/>
                </IconButton>
              }
              icon={<ReportOutlinedIcon className='w-6 h-6'/>}
            >
              {props.alretText}
            </Alert>
          </Collapse>
          
        </Box>
      );
}

export default memo(ErrorAlert);