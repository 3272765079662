import React, { createContext, useContext, useEffect, useState, useMemo} from "react";
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

//create context
const UserContext = createContext();

//custom hook to use the context 
export const useUserContext = () => {
    return useContext(UserContext)
};


//provider component
export const UserProvider = ({children}) => {

    const [userName, setUserName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userId, setUserId] = useState('')
    const [orgName, setorgName] = useState('')
    const [accessControl, setAccessControl] = useState([])
    const [devMode, setDevMode] = useState(false)
    const [orgLogoCustom, setOrgLogoCustom] = useState(null)
    const location = useLocation();

    //get user details

    useEffect(()=>{

        console.log('get from local', Cookies.get('userEmail'))
        if(Cookies.get('userEmail')){
            setUserEmail(Cookies.get('userEmail'))
        }
        if(Cookies.get('userName')){
            setUserName(Cookies.get('userName'))
        }
        if(Cookies.get('userId')){
            setUserId(Cookies.get('userId'))
        }
        if(Cookies.get('access')){
            setAccessControl(JSON.parse(Cookies.get('access')))
        }
        if(Cookies.get('orgName')){
            setorgName(Cookies.get('orgName'))
        }
        if(localStorage.getItem('customLogo')){
            setOrgLogoCustom(localStorage.getItem('customLogo'))
        }

    },[])

    //dev mode handling

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const isDevMode = searchParams.get('devMode') === 'true';
    
        if (devMode !== isDevMode) setDevMode(isDevMode);
    
        const storedAccess = Cookies.get('access') ? JSON.parse(Cookies.get('access')) : [];
        const newAccessControl = isDevMode ? [...storedAccess, 'unknown_map'] : storedAccess;
        if (JSON.stringify(accessControl) !== JSON.stringify(newAccessControl)) {
          setAccessControl(newAccessControl);
        }
    }, [location, devMode, accessControl]);
    

    //set user details from login

    const setUserDetails = (name, email, id, permissions, orgName, orgLogo) => {

        if (name !== undefined) setUserName(name);
        if (email !== undefined) setUserEmail(email);
        if (id !== undefined) setUserId(id);
        if (permissions !== undefined) setAccessControl(permissions);
        if (orgName !== undefined) setorgName(orgName);
        if (orgLogo !== undefined) setOrgLogoCustom(orgLogo);

    }

    useEffect(()=>{
        console.log('data.permissions context', accessControl)
    },[accessControl])

    const contextValue = useMemo(() => ({
        userName,
        userEmail,
        userId,
        accessControl,
        orgName,
        devMode,
        orgLogoCustom,
        setUserDetails
    }), [userName, userEmail, userId, accessControl, orgName, devMode, orgLogoCustom]);

    return(
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    )
}