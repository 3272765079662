import axios from "axios";
import Cookies from 'js-cookie'
import {useNavigate} from 'react-router-dom';
import { useBaseUrlContext } from "../context/Base_url";
import { useAuthContext } from "../context/Auth";
import { useAuth0 } from "@auth0/auth0-react";


// Create an Axios instance
const axiosInstance = axios.create();

const useAxiosInstance = () => {

    const {BaseUrl} = useBaseUrlContext()
    const navigate = useNavigate();
    const {tokenReview, tokenRenew, errAlertOpen, errAlertText} = useAuthContext()
    const { logout } = useAuth0();

    //varibles

    const MAX_RETRY_COUNT = 2;
    const UNAUTHORIZED = 401;
    const PAYMENT_REQUIRED = 402;
    const NOT_FOUND = 404;
    const CUSTOM_ERROR = 455;

    const TOKEN_COOKIE_NAME = 'token';
    const AUTH_HEADER_NAME = 'Authorize';
    const HOME_URL = '/';

    // Set the base URL for the Axios instance

    axiosInstance.defaults.baseURL = BaseUrl;

    //handle logout

    const handleLogout = () => {
        console.log('Token invalid or expired. Logging out...');
        setTimeout(() => {
            logout({ 
              logoutParams: { returnTo: `${window.location.origin}/` } 
            });
        }, 3000);
    };

    // Request interceptor to add the token to headers

    axiosInstance.interceptors.request.use(
        async (config) => {
          try {
            const tokenValid = await tokenReview();
            
            if (tokenValid) {
              const token = Cookies.get(TOKEN_COOKIE_NAME);
              if (token) {
                config.headers[AUTH_HEADER_NAME] = token;
              } else {
                console.warn('Token is valid but not found in cookies');
              }
            } else {
              handleLogout();
              // Optional, can reject the request here
              // return Promise.reject(new Error('Authentication failed'));
            }
            
            return config;
          } catch (error) {
            console.error('Error in request interceptor:', error);
            handleLogout();
            return Promise.reject(error);
          }
        },
        (error) => {
          console.error('Request interceptor error:', error);
          return Promise.reject(error);
        }
      );
    

    //err handling
   
    const handleSpecificErrors = (status, originalResponse) => {
        if (status === UNAUTHORIZED || status === CUSTOM_ERROR) {
            console.log(`${status} error - logging out`);
            handleLogout()
        } else if (status === NOT_FOUND) {
            navigate('/404');
        } else {
            console.log('originalResponse', originalResponse);
            errAlertText(originalResponse.data.error.text);
            errAlertOpen(true);
            // Set a flag to indicate that an alert has been shown
            originalResponse.alertShown = true;
        }
    };

    //retry to get new token

    const retryRequest = async (axiosInstance, error) => {
        const { config: originalRequest, response: originalResponse } = error;
        const status = originalResponse ? originalResponse.status : null;
      
        if (status >= 400 && status < 600) {
          originalRequest._retryCount = (originalRequest._retryCount || 0) + 1;
      
          if (originalRequest._retryCount <= MAX_RETRY_COUNT) {
            console.log(`Retry attempt ${originalRequest._retryCount} for status ${status}`);
      
            if (status === UNAUTHORIZED || status === PAYMENT_REQUIRED) {
              const tokenRenewed = await tokenRenew();
              if (tokenRenewed) {
                originalRequest.headers['Authorize'] = Cookies.get('token');
                return axiosInstance(originalRequest);
              }
            }
      
            if (originalRequest._retryCount === MAX_RETRY_COUNT) {
              handleSpecificErrors(status, originalResponse);
            }
          }
        }
      
        return Promise.reject(error);
    };

    // Response interceptor to handle token renewal

    axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
          console.log('Interceptor error:', error);
          return retryRequest(axiosInstance, error);
        }
    );

    return axiosInstance;

}


export default useAxiosInstance;