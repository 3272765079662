import React, {useState, useEffect} from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useUserContext } from '../context/User_Access';

import Container from '@mui/material/Container';

import CreateWorkSpaceForm from '../components/auth-form/Create_workspace_form';
import SuccessAlert from '../components/alerts/Success_alert';
import ErrorAlert from '../components/alerts/Fail_alert';
import NavBarSimple from '../components/nav/Nav_simple';

const CreateWorkSpace = (props) =>{
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openFail, setOpenFail] = useState(false);
    const [textSuccess, setTextSuccess] = useState('');
    const [textFail, setTextFail] = useState('');
    const { user, isAuthenticated, isLoading } = useAuth0();
    const [userAuthenticated, setUserAuthenticated] = useState(false);
    const {setUserDetails} = useUserContext()

    useEffect(()=>{

        console.log('user create work', user, 'isAuthenticated', isAuthenticated, 'isLoading', isLoading)
        
        if(isAuthenticated){

            sessionStorage.setItem('authSession', JSON.stringify(user))
            setUserAuthenticated(true)
            setUserDetails(user?.nickname, user?.email, '', [], '')
            console.log('Auth err if')
            
        }
        else{

            if(!isLoading){
                setUserAuthenticated(false)
                setOpenFail(true)
                setTextFail('Unauthenticated user')
                console.log('Auth err else')
            }

        }
        
    },[user, isAuthenticated, isLoading])

    useEffect(()=>{
        setTimeout(()=>{
            setOpenFail(false);
        },5000)
    },[openFail]);

    //has subdomain

    useEffect(()=>{

        const hostname = window.location.hostname;
        const parts = hostname.split('.');
        
        if(!hostname.includes('localhost')){
          
            if(parts.length > 2 ){
                sessionStorage.setItem('subDomain', parts.at(0))
            }
            else{
                sessionStorage.setItem('subDomain', '')
            }

        }
        else{
            sessionStorage.setItem('subDomain', '')
        }

    },[])

    
    return(
        <>
            <div className='absolute top-8 right-8 w-full z-[1999] h-0'>
                <SuccessAlert setOpenSuccess={setOpenSuccess} openSuccess={openSuccess} alretText={textSuccess}/>
                <ErrorAlert setOpenFail={setOpenFail} openFail={openFail} alretText={textFail}/>
            </div>
            <NavBarSimple isUserLogged={true}/>
            <Container maxWidth="xl" className='relative'>
                <div className='flex flex-col justify-center items-center h-[calc(100vh-6rem)] my-12 relative'>
                    <CreateWorkSpaceForm setOpenSuccess={setOpenSuccess} setTextSuccess={setTextSuccess} setOpenFail={setOpenFail} setTextFail={setTextFail} openFail={openFail} userAuthenticated={userAuthenticated}/>
                </div>
                <div className='text-center h-12 flex justify-center absolute bottom-0 w-[calc(100%-3rem)]'>
                    <div className='my-3 px-3 flex items-center text-base text-sonicSilver font-medium'>
                        By signing up, you agree to the <a href='#' target='_blank' className=' text-sonicSilver underline mx-1'>Terms of Service</a> and <a href='#' target='_blank' className=' text-sonicSilver underline ml-1'>Privacy Policy</a>
                    </div>
                </div>
            </Container> 
        </> 
    );
}

export default CreateWorkSpace;