import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import axios from "axios";
import { useBaseUrlContext } from '../../context/Base_url';

import {Box, OutlinedInput , FormControl, InputLabel, Tooltip, InputAdornment, Typography } from '@mui/material';

import infoCircle from '../../assets/info-green-outline.svg'
import ButtonSecondaryLoader from '../button/Btn_secondary_loader';


const CreateWorkSpaceForm = (props) => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const {BaseUrl} = useBaseUrlContext()
    const [btnLoading, setBtnLoading] = useState(false);


    //fail alert ###

    useEffect(()=>{
        setTimeout(()=>{
            props.setOpenFail(false);
        },5000)
    },[props.openFail]);


    const onSubmit = (data) => {

        setBtnLoading(true);

        console.log('submit', data);

        const authData = JSON.parse(sessionStorage.getItem('authSession'))

        const dataObj = {

            "user":{
                "email": authData.email,
                "name":data.name,
            },
            "workspace":{
                "company":data.company,
                "domain":data.domain
            }

        }

        axios
            .post(`${BaseUrl}/create_workspace`, dataObj)
            .then((res)=>{
                let data = res.data.result;
                let error = res.data.error;
                //console.log('res', data.accessToken, error.text);
                if(error && error.text === '' &&  error.code === ''){
                    //window.location.href = window.location.origin;
                    //localStorage.setItem('sessionObj', JSON.stringify(data));

                    const reToken = data.auth.authorization.refreshToken;
                    // Construct the URL with the subdomain
                    const subdomain = data.tenant; 
                    const path = '/welcome'; 
                    const fullUrl = `${window.location.protocol}//${subdomain}.${window.location.host}${path}?key=${reToken}`;

                    // Navigate to the subdomain page
                    window.location.href = fullUrl;
                }
                else{
                    props.setOpenFail(true)
                    props.setTextFail(error.text)
                    console.log(error.text);
                    //alert(error.text);
                }
                setBtnLoading(false);
            })
            .catch((err)=>{
                console.log('err', err);
                props.setOpenFail(true)
                //alert('The server encounted an error and could not complete your request.', err);
                err.response.data.error.text ? props.setTextFail(err.response.data.error.text):props.setTextFail('The server encounted an error and could not complete your request.')
                setBtnLoading(false);
            });

    }        

    return (
            <div className='max-w-[35rem] bg-white rounded-3xl'>
                <Box component="form"  autoComplete="off" className='py-7' id="signin" onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex items-center flex-col justify-center m-12'>
                        <Typography variant="textMedium28" className="mb-3" component='div'>
                            Create a new workspace
                        </Typography>
                        <Typography variant="text18" className="text-center" component='div'>
                            Workspaces are shared environments where teams can collaborate on documents.
                        </Typography>
                    </div>
                    <FormControl variant="standard" fullWidth  margin="normal" className='px-12 '>
                        <InputLabel shrink htmlFor="company-input">Company</InputLabel>
                        <OutlinedInput 
                            id="company-input"
                            autoComplete="off" 
                            placeholder="Enter company name" 
                            type='text'
                            {...register("company",{ required: true })} 
                        />
                        {errors.company && <span className='err-msg'>Company name is required.</span>}
                    </FormControl>
                    <FormControl variant="standard" fullWidth  margin="normal" className='px-12 '>
                        <InputLabel shrink htmlFor="domain-input">Domain</InputLabel>
                        <div className='relative'>
                            <OutlinedInput 
                                id="domain-input"
                                endAdornment={<InputAdornment position="end" className='domain-adornment'>@docupath.ai</InputAdornment>}
                                autoComplete="off" 
                                placeholder="Enter domain" 
                                type='text'
                                {...register("domain",{ required: true })} 
                            />
                            <div className='absolute right-[-7%] top-[33%]'>
                                <Tooltip 
                                    title={
                                    <div className='tooltip-row'>
                                        <div className='info-row'>
                                            <div className='title'>Info:</div>
                                            <div className='detail'>This will also be used as email</div>
                                        </div>
                                    </div>} 
                                    arrow 
                                    placement="right"
                                    classes={{ tooltip: 'action-info' }}
                                >
                                        <img src={infoCircle} alt="info" className="w-5" />
                                </Tooltip>
                            </div>
                        </div>
                        {errors.domain && <span className='err-msg'>Domain is required.</span>}
                    </FormControl>
                    <FormControl variant="standard" fullWidth  margin="normal" className='px-12 '>
                        <InputLabel shrink htmlFor="company-input">User name</InputLabel>
                        <OutlinedInput 
                            id="name-input"
                            autoComplete="off" 
                            placeholder="Enter name" 
                            type='text'
                            {...register("name",{ required: true })} 
                        />
                        {errors.name && <span className='err-msg'>User name is required.</span>}
                    </FormControl>
                    <div className='mt-4 px-12 mb-12' >
                        <ButtonSecondaryLoader variant={'secondaryBtn'} type='submit' btnName='Create Workspace' btnLoading={btnLoading} disabled={props.userAuthenticated ? false : true} classes="w-full py-[1.125rem]" />
                    </div>
                </Box>
            </div>    
    );
}

export default CreateWorkSpaceForm;