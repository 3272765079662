import React from 'react';

import notAllowIcon from '../assets/not-allow.gif'

const NotAccess = (props) => {

    return(
        <div className={`${props.class? props.class:'h-screen'} flex justify-center items-center flex-col`}>
            <img src={notAllowIcon} alt='exit icon' className='max-w-[7rem] w-full mb-7'/>
            <div className="text-lg font-sans font-semibold mb-2">You do not have permission to access this feature.</div> 
        </div>
    )
}

export default NotAccess;