import React, {useState, useEffect} from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import {Container, Typography} from '@mui/material';

import SuccessAlert from '../components/alerts/Success_alert';
import ErrorAlert from '../components/alerts/Fail_alert';

import loaderIcon from '../assets/Loading1.svg'

const CreateAccount = (props) =>{

    const [openSuccess, setOpenSuccess] = useState(false);
    const [openFail, setOpenFail] = useState(false);
    const [textSuccess, setTextSuccess] = useState('');
    const [textFail, setTextFail] = useState('');
    const { loginWithRedirect } = useAuth0();

    useEffect(()=>{
        setTimeout(()=>{
            setOpenFail(false);
        },10000)
    },[openFail]);

    useEffect(()=>{
        console.log(openSuccess)
        setTimeout(()=>{
            setOpenSuccess(false);
        },10000)
    },[openSuccess]);

    //redirect to auth login

    useEffect(()=>{
        
        loginWithRedirect()

    },[])

    return(
        <>
            <div className='absolute top-8 right-8 w-full z-[1999] h-0'>
                <SuccessAlert setOpenSuccess={setOpenSuccess} openSuccess={openSuccess} alretText={textSuccess}/>
                <ErrorAlert setOpenFail={setOpenFail} openFail={openFail} alretText={textFail}/>
            </div>
             <Container maxWidth="xl">
                <div className={`${props.class? props.class:'h-screen'} flex justify-center items-center flex-col`}>
                    <div className='flex flex-col items-center justify-center'>

                        <img className="max-w-[2.5rem] min-h-[2.5rem]" src={loaderIcon} alt="loading logo"/>
                        <Typography variant="text24" className="!font-medium mt-7" component='div'>
                            Please wait...
                        </Typography>
                        
                    </div>
                </div>
            </Container> 
        </> 
    );
}

export default CreateAccount;